<template>
  <div class="row">
    <div class="col-12">
      <c-step
        stepperGrpCd="IBM_STEP_CD"
        :currentStepCd="impr.ibmStepCd"
        :disabled="true"
        v-model="impr.ibmStepCd">
      </c-step>
        <!-- :items="stepItems"  -->
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row">
        <div class="col-12">
          <q-form ref="editForm1">
            <c-card title="조치요청 상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <!-- <c-btn 
                    v-show="imprCompleteEditable" 
                    :isSubmit="isApprove"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="승인" 
                    icon="check"
                    @beforeAction="approveImpr"
                    @btnCallback="approveCallback" />
                  <c-btn 
                    v-show="imprCancleEditable" 
                    :isSubmit="isCancle"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="반려" 
                    icon="delete_forever"
                    @beforeAction="cancleImpr"
                    @btnCallback="cancleCallback" />-->
                  <c-btn  
                    v-show="imprEditable" 
                    :isSubmit="isSave"
                    :url="saveUrl"
                    :param="impr"
                    :mappingType="mappingType"
                    label="요청상세저장" 
                    icon="save" 
                    @beforeAction="saveActionImpr"
                    @btnCallback="saveActionCallback" />
                  <c-btn v-if="removeEditable" label="삭제" icon="remove" @btnClicked="removeImpr" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-select
                    :disabled="true"
                    :editable="editable"
                    codeGroupCd="IBM_TASK_TYPE_CD"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="ibmTaskTypeCd"
                    label="업무"
                    v-model="impr.ibmTaskTypeCd"
                  ></c-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-text
                    :disabled="true"
                    :editable="editable"
                    label="점검자(조치요청자)"
                    name="request"
                    :value="request">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-text
                    :required="true"
                    :editable="imprEditable"
                    label="제목"
                    name="ibmTitle"
                    v-model="impr.ibmTitle">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :required="true"
                    :editable="imprEditable"
                    label="개선대책"
                    :rows="3"
                    name="improveRequestContents"
                    v-model="impr.improveRequestContents">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-vendor
                    :required="true"
                    :plantCd="impr.plantCd"
                    :changeItem="changeItem"
                    label="개선담당업체"
                    name="actionDeptCd"
                    v-model="impr.actionDeptCd" />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-field 
                    :required="true"
                    :editable="imprEditable"
                    :plantCd="impr.plantCd"
                    :vendorCd="impr.actionDeptCd"
                    :changeItem="changeItem"
                    :isAllVendor="true"
                    type="user" 
                    label="개선담당자" 
                    name="actionUserId" 
                    v-model="impr.actionUserId" />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :required="true"
                    :editable="imprEditable"
                    label="개선완료 예정일"
                    name="improveCompleteExpectDate"
                    v-model="impr.improveCompleteExpectDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-plant
                    :editable="false"
                    :required="true"
                    :changeItem="changeItem"
                    type="edit" 
                    name="plantCd" 
                    v-model="impr.plantCd" />
                </div>
                <!-- <div class="col-xs-9 col-sm-5 col-md-5 col-lg-5">
                  <c-text
                    :editable="false"
                    type="edit" 
                    label="관련 공종 및 위치정보"
                    name="processName" 
                    v-model="impr.processName" />
                </div>
                <div class="col-xs-3 col-sm-1 col-md-1 col-lg-1">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <c-btn 
                      icon="search"
                      @btnClicked="srcClick" />
                  </div>
                </div> -->
                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-field
                    :required="true"
                    :editable="imprCheckEditable"
                    :plantCd="impr.plantCd"
                    :vendorCd="impr.actionDeptCd"
                    :isVendorOrg="true"
                    type="user" 
                    label="승인자" 
                    name="approveUserId" 
                    v-model="impr.approveUserId" />
                </div> -->
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <q-form ref="editForm2">
        <c-card title="조치상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn 
                v-show="imprActionEditable" 
                :isSubmit="isActionComplete"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="개선완료" 
                icon="directions_run"
                @beforeAction="actionCompleteImpr"
                @btnCallback="actionCompleteCallback" />
              <c-btn 
                v-show="imprEditable2" 
                :isSubmit="isSave2"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="조치상세저장" 
                icon="save" 
                @beforeAction="saveActionImpr2"
                @btnCallback="saveActionCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <c-datepicker
                :required="true"
                :editable="imprEditable2"
                label="조치완료일"
                name="actionCompleteDate"
                v-model="impr.actionCompleteDate"
              />
            </div>
            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <c-datepicker
                :editable="actionAreaEditable"
                :required="true"
                label="검토완료 요청일"
                name="reviewCompleteRequestDate"
                v-model="impr.reviewCompleteRequestDate"
              />
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-textarea
                :required="true"
                :editable="imprEditable2"
                label="개선조치내역"
                name="actionContents"
                v-model="impr.actionContents">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-6">
                  <c-card title="개선전 사진" class="cardClassDetailForm">
                    <template slot="card-detail">
                      <div class="col-12">
                        <c-upload-picture 
                          height="300px"
                          :attachInfo="attachBeforeInfo"
                          :editable="imprEditable2"
                          label="개선 전 사진">
                        </c-upload-picture>
                      </div>
                    </template>
                  </c-card>
                </div>
                <div class="col-6">
                  <c-card title="개선후 사진" class="cardClassDetailForm">
                    <template slot="card-detail">
                      <div class="col-12">
                        <c-upload-picture 
                          height="300px"
                          :attachInfo="attachAfterInfo"
                          :editable="imprEditable2"
                          label="개선 후 사진">
                        </c-upload-picture>
                      </div>
                    </template>
                  </c-card>
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          isSearch: false,
          sopImprovementId: '',
          ibmTaskTypeCd: '',
          relationTableKey: '',
          requestContents: '',
          data: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    }
  },
  data() {
    return {
      impr: {
        sopImprovementId: '',  // 개선 일련번호
        plantCd: '',  // 공사현장 코드
        ibmTaskTypeCd: '',  // 업무분류코드
        ibmTitle: '',  // 제목
        request: '',
        processCd: '',
        processName: '',
        improveRequestDeptCd: '',  // 개선요청부서
        improveRequestDeptName: '', // 개선요청부서명
        improveRequestUserId: '',  // 개선요청자
        improveRequestUserName: '',  // 개선요청자명
        improveRequestContents: '',  // 개선요청내용
        ibmStepCd: '',  // 개선진행단계
        relationTableKey: '',  // 관련테이블 키
        relationVendor: '',  // 관련업체_기술
        vendorCompleteFlag: '',  // 조치업체완료여부
        actionScheduleDate: '',  // 조치예정일
        actionCompleteRequestDate: '',  // 조치완료 요청일
        // reviewCompleteRequestDate: '',  // 검토완료 요청일
        actionCompleteDate: '',  // 조치완료일
        actionDeptCd: '',  // 조치부서_조치부서는 변경가능
        actionUserId: '',  // 조치부서_조치부서는 변경가능
        actionContents: '',  // 조치내용_조치부서에서
        // reviewContents: '',  // 요청부서 조치결과 검토_요청부서에서
        actionCompleteCheckUserId: '',  // 조치완료 확인자_부서장 또는 공장장
        actionCompleteCheckFlag: '',  // 조치완료 확인자 확인 여부
        requestRejectReason: '', // 요청취소사유
        companyCd: '',
        regUserId: '',  // 등록자 ID
        regDt: '',
        chgUserId: '',  // 수정자 ID
        approveUserId: '',
        sopMapId: '',
        // maps: [],
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_AFTER',
        taskKey: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: true,
      mappingType: 'PUT',
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isRequest: false, // 요청 flag
      isActionComplete: false, // 조치완료 flag
      isComplete: false, // 개선완료 flag
      isCancle: false,
      isApprove: false,
      isSave: false,
      isSave2: false,
      insertUrl: '',
      updateUrl: '',
      isImproveComplete: false,
    };
  },
  computed: {
    // 승인자, 점검자 개선완료될때까지 수정가능
    // 조치업체가 들어왔을경우
    request() {
      return this.impr.improveRequestDeptName + ' / ' + this.impr.improveRequestUserName
    },
    disabled() {
      return this.impr.ibmStepCd === 'IS00000020'
    },
    // 기본 editable : 승인자, 점검자, 조치업체 수정가능
    imprEditable() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return this.editable && !this.disabled && (this.$store.getters.user.deptCd === this.impr.actionDeptCd);
      } else {
        return this.editable && !this.disabled
      }
    },
    imprEditable2() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return this.editable && Boolean(this.popupParam.sopImprovementId) && (this.$store.getters.user.deptCd === this.impr.actionDeptCd);
      } else {
        return this.editable && Boolean(this.popupParam.sopImprovementId) 
        
      }
    },
    imprCheckEditable() {
      return this.editable && !this.disabled
    },
    imprIsSelfEditable() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return this.$store.getters.user.deptCd === this.impr.actionDeptCd;
      } else {
        return this.$store.getters.user.selfVendorCd === this.impr.actionDeptCd;
      }
    },
    imprActionEditable() {
      return this.imprEditable && this.imprIsSelfEditable && (this.impr.ibmStepCd !== 'IS00000010' && this.impr.ibmStepCd !== 'IS00000015') && Boolean(this.popupParam.sopImprovementId);
    },
    removeEditable() {
      return this.impr.ibmStepCd === 'IS00000001' && Boolean(this.popupParam.sopImprovementId);
    },
    // 개선완료 및 반려 버튼 활성화 -> 승인자와 진행상태 : 조치완료, 반려
    imprCompleteEditable() {
      return  (this.impr.ibmStepCd === 'IS00000010' || this.impr.ibmStepCd === 'IS00000015') && 
        (this.$store.getters.user.userId === this.impr.approveUserId)
    }, 
    // 반려버튼 활성화  -> 승인자와 진행상태 : 조치완료
    imprCancleEditable() {
      return  this.impr.ibmStepCd === 'IS00000010' && 
        (this.$store.getters.user.userId === this.impr.approveUserId)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.get.url;
      this.insertUrl = transactionConfig.sop.ibm.improve.insert.url;
      this.updateUrl = transactionConfig.sop.ibm.improve.update.url;
      this.saveUrl = transactionConfig.sop.ibm.improve.update.url;
      this.deleteUrl = transactionConfig.sop.ibm.improve.delete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopImprovementId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopImprovementId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.impr = _result.data;

          this.attachBeforeInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)
          this.attachAfterInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)

          this.returnData.col1 = this.impr;
        },);
      } else {
        /**
         * 데이터가 들어오지 않은 경우 두 가지의 case로 처리
         * 
         * case 1. popupParam.data가 있는 경우
         *  - 해당 데이터에 새로 들어온 데이터가 있음으로 해당 데이터를 impr에 extend 처리
         * case 2. popupParam.data가 없는 경우 경우
         *  - 해당 팝업으로 개선요청을 등록하는 경우로 업무 및 요청정보를 setting한다.
         */
        if (this.popupParam.data) {
          // case 1.
          this.$_.extend(this.impr, this.popupParam.data)
        } else {
          // case 2.
          this.impr.plantCd = this.$store.getters.user.plantCd;
          this.impr.actionDeptCd = this.$store.getters.user.deptCd;
          this.impr.ibmClassCd = 'IC00000001';
          this.impr.ibmStepCd = 'IS00000001'
          this.impr.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
          this.impr.improveRequestDeptCd = this.$store.getters.user.deptCd;
          this.impr.improveRequestDeptName = this.$store.getters.user.deptName;
          this.impr.improveRequestUserId = this.$store.getters.user.userId;
          this.impr.improveRequestUserName = this.$store.getters.user.userName;
          this.impr.regDt = this.$comm.getToday();
          this.impr.regUserId = this.$store.getters.user.userId;
          this.impr.relationTableKey = this.popupParam.relationTableKey;
          this.impr.ibmTitle = this.popupParam.requestContents.replace(/^[\s\uFEFF\xA0]+/gi, '') + '_' + this.$comm.getToday();
        }
      }
    },
    /**
     * 개선조치요청 -> 조치중
     */
    saveActionImpr() {
      if (this.popupParam.sopImprovementId) {
        this.mappingType = 'PUT';
        this.saveUrl = this.updateUrl
      } else {
        this.mappingType = 'POST';
        this.saveUrl = this.insertUrl
        this.impr.companyCd = this.$store.getters.user.companyCd;
      }
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveActionImpr2() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId
              
              this.isSave2 = !this.isSave2
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveActionCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 요청삭제
     */
    removeImpr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopImprovementId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', this.returnData, 'delete');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    rejectCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치완료
     */
    actionCompleteImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        this.$refs['editForm2'].validate().then(__result => {
          if (_result && __result) {
            let message = '조치완료하시겠습니까?'
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: message,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                //this.impr.ibmStepCd = 'IS00000010';
                this.impr.ibmStepCd = 'IS00000020';
                
                this.isCancle = !this.isCancle
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      });
    },
    actionCompleteCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치완료 -> 승인 -> 개선완료
     */
    approveImpr() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          let message = '승인하시겠습니까?'
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000020';
              
              this.isApprove = !this.isApprove
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approveCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치완료 -> 조치상태반려
     */
    cancleImpr() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          let message = '반려하시겠습니까?'
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000015';
              
              this.isActionComplete = !this.isActionComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    cancleCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 반려 -> 개선완료
     */
    improveCompleteImpr() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '개선완료처리하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000020';
              
              this.isImproveComplete = !this.isImproveComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    improveCompleteCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    srcClick() {
      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        maps: this.impr.maps,
        sopMapId: this.impr.sopMapId,
        disabled: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>