var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-step", {
            attrs: {
              stepperGrpCd: "IBM_STEP_CD",
              currentStepCd: _vm.impr.ibmStepCd,
              disabled: true,
            },
            model: {
              value: _vm.impr.ibmStepCd,
              callback: function ($$v) {
                _vm.$set(_vm.impr, "ibmStepCd", $$v)
              },
              expression: "impr.ibmStepCd",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "q-form",
                { ref: "editForm1" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "조치요청 상세" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.imprEditable,
                                    expression: "imprEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isSave,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: _vm.mappingType,
                                  label: "요청상세저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveActionImpr,
                                  btnCallback: _vm.saveActionCallback,
                                },
                              }),
                              _vm.removeEditable
                                ? _c("c-btn", {
                                    attrs: { label: "삭제", icon: "remove" },
                                    on: { btnClicked: _vm.removeImpr },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-select", {
                              attrs: {
                                disabled: true,
                                editable: _vm.editable,
                                codeGroupCd: "IBM_TASK_TYPE_CD",
                                type: "edit",
                                itemText: "codeName",
                                itemValue: "code",
                                name: "ibmTaskTypeCd",
                                label: "업무",
                              },
                              model: {
                                value: _vm.impr.ibmTaskTypeCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "ibmTaskTypeCd", $$v)
                                },
                                expression: "impr.ibmTaskTypeCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                disabled: true,
                                editable: _vm.editable,
                                label: "점검자(조치요청자)",
                                name: "request",
                                value: _vm.request,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                required: true,
                                editable: _vm.imprEditable,
                                label: "제목",
                                name: "ibmTitle",
                              },
                              model: {
                                value: _vm.impr.ibmTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "ibmTitle", $$v)
                                },
                                expression: "impr.ibmTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                required: true,
                                editable: _vm.imprEditable,
                                label: "개선대책",
                                rows: 3,
                                name: "improveRequestContents",
                              },
                              model: {
                                value: _vm.impr.improveRequestContents,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.impr,
                                    "improveRequestContents",
                                    $$v
                                  )
                                },
                                expression: "impr.improveRequestContents",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-vendor", {
                              attrs: {
                                required: true,
                                plantCd: _vm.impr.plantCd,
                                changeItem: _vm.changeItem,
                                label: "개선담당업체",
                                name: "actionDeptCd",
                              },
                              model: {
                                value: _vm.impr.actionDeptCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "actionDeptCd", $$v)
                                },
                                expression: "impr.actionDeptCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-field", {
                              attrs: {
                                required: true,
                                editable: _vm.imprEditable,
                                plantCd: _vm.impr.plantCd,
                                vendorCd: _vm.impr.actionDeptCd,
                                changeItem: _vm.changeItem,
                                isAllVendor: true,
                                type: "user",
                                label: "개선담당자",
                                name: "actionUserId",
                              },
                              model: {
                                value: _vm.impr.actionUserId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "actionUserId", $$v)
                                },
                                expression: "impr.actionUserId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                required: true,
                                editable: _vm.imprEditable,
                                label: "개선완료 예정일",
                                name: "improveCompleteExpectDate",
                              },
                              model: {
                                value: _vm.impr.improveCompleteExpectDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.impr,
                                    "improveCompleteExpectDate",
                                    $$v
                                  )
                                },
                                expression: "impr.improveCompleteExpectDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-plant", {
                              attrs: {
                                editable: false,
                                required: true,
                                changeItem: _vm.changeItem,
                                type: "edit",
                                name: "plantCd",
                              },
                              model: {
                                value: _vm.impr.plantCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "plantCd", $$v)
                                },
                                expression: "impr.plantCd",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" },
        [
          _c(
            "q-form",
            { ref: "editForm2" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "조치상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imprActionEditable,
                                expression: "imprActionEditable",
                              },
                            ],
                            attrs: {
                              isSubmit: _vm.isActionComplete,
                              url: _vm.saveUrl,
                              param: _vm.impr,
                              mappingType: "PUT",
                              label: "개선완료",
                              icon: "directions_run",
                            },
                            on: {
                              beforeAction: _vm.actionCompleteImpr,
                              btnCallback: _vm.actionCompleteCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imprEditable2,
                                expression: "imprEditable2",
                              },
                            ],
                            attrs: {
                              isSubmit: _vm.isSave2,
                              url: _vm.saveUrl,
                              param: _vm.impr,
                              mappingType: "PUT",
                              label: "조치상세저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveActionImpr2,
                              btnCallback: _vm.saveActionCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.imprEditable2,
                            label: "조치완료일",
                            name: "actionCompleteDate",
                          },
                          model: {
                            value: _vm.impr.actionCompleteDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "actionCompleteDate", $$v)
                            },
                            expression: "impr.actionCompleteDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            required: true,
                            editable: _vm.imprEditable2,
                            label: "개선조치내역",
                            name: "actionContents",
                          },
                          model: {
                            value: _vm.impr.actionContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "actionContents", $$v)
                            },
                            expression: "impr.actionContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "c-card",
                                {
                                  staticClass: "cardClassDetailForm",
                                  attrs: { title: "개선전 사진" },
                                },
                                [
                                  _c("template", { slot: "card-detail" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("c-upload-picture", {
                                          attrs: {
                                            height: "300px",
                                            attachInfo: _vm.attachBeforeInfo,
                                            editable: _vm.imprEditable2,
                                            label: "개선 전 사진",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "c-card",
                                {
                                  staticClass: "cardClassDetailForm",
                                  attrs: { title: "개선후 사진" },
                                },
                                [
                                  _c("template", { slot: "card-detail" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("c-upload-picture", {
                                          attrs: {
                                            height: "300px",
                                            attachInfo: _vm.attachAfterInfo,
                                            editable: _vm.imprEditable2,
                                            label: "개선 후 사진",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }